@font-face{font-family: 'CeraPro';src: url('./fonts/CeraProBlack.otf') format('opentype');font-weight: normal;font-style: normal;font-display: block;}
@font-face{font-family: 'CeraProThin';src: url('./fonts/CeraProThin.otf') format('opentype');font-weight: normal;font-style: normal;font-display: block;}
* {margin: 0;padding: 0;box-sizing: border-box;}
section{color: #ffffff;height: 100vh;width: 99vw;display: flex;align-items: center;justify-content: center;position: relative;flex-direction: column;}

/*Texts*/
h1{font-size: 14vmin;text-transform: uppercase;font-weight: 400;text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);text-align: center;font-family: 'CeraPro', Helvetica, Arial, sans-serif;}
a{text-decoration: none;color: #ffffff;height: 100%;width: 100%;}
.experience{position: fixed;width: 100vw;height: 100vh;}

/*Cards*/
.card{padding: 5rem 10rem;border-radius: 1rem;margin: auto 25rem;;}
span{font-family: 'CeraProThin', Helvetica, Arial, sans-serif;font-size: 2rem;}
.border-gradient {border: 10px solid;border-image-slice: 1;border-width: 5px;}
.border-gradient-color {border-image-source: linear-gradient(to left, #ff0a00, #ffaf00);}

/*Bordures*/
html:before,html:after,body:before,body:after{content:"";background:#ff0a00;position:fixed;display:block;z-index:5;}
html:before{height:10px;left:0;right:0;top:0;}
html:after{width:10px;top:0;right:0;bottom:0;}
body:before{height:10px;right:0;bottom:0;left:0;}
body:after{width:10px;top:0;bottom:0;left:0;}